export class ContractTemplate {
  constructor() {
    this.id = ""
    this.name = ""
    this.description = ""
    this.jobTitle = ""
    this.duties = []
    this.contractCurrency = ""
    this.terminationNoticePeriod = 0
    this.wagePaymentSchedule = ""
    this.wagePaymentDate = 0
    this.wagePaymentWeekDay = ""
    this.wagePeriod = ""
    this.wagePeriodUnitType = ""
    this.wageTracking = ""
    this.wageRate = 0
    this.overtimeAvailable = false
    this.overtimeRate = 0
    this.publicHolidayRate = 0
    this.transportAllowanceRate = 0
    this.transportAllowancePeriod = ""
    this.accommodationAllowanceRate = 0
    this.accommodationAllowancePeriod = ""
    this.mealAllowanceRate = 0
    this.mealAllowancePeriod = ""
    this.hoursOfWorkStart = ""
    this.hoursOfWorkEnd = ""
    this.workSchedule = ""
    this.lunchPeriod = 0
    this.leaveAvailable = false
    this.annualLeaveDays = 0
    this.sickLeaveDays = 0
    this.maternityLeaveDays = 0
    this.familyResponsibilityLeaveDays = 0
  }
}
