<template>
  <div>
    <v-breadcrumbs :items="crumbs"></v-breadcrumbs>
    <base-loader :isLoading="isLoading"></base-loader>
    <v-row>
      <v-col md="12">
        <v-card>
          <v-card-title class="text-md-h3 primary--text">
            Edit Contract Template
          </v-card-title>
          <v-card-text>
            <v-form ref="contractTemplateForm" @submit.prevent="save()">
              <v-row>
                <v-col md="6">
                  <v-text-field
                    label="Template Name"
                    v-model="contractTemplateModel.name"
                    placeholder="Domestic Worker Contract"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="6">
                  <v-text-field
                      label="Job Title / Role"
                      v-model="contractTemplateModel.jobTitle"
                      outlined
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="6">
                  <v-textarea
                      label="Last Name"
                      v-model="contractTemplateModel.description"
                      outlined
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="6">
                  <v-select
                      v-model="contractTemplateModel.duties"
                      :items="contractTemplateJobResponsibilitiesOptions"
                      item-text="text"
                      item-value="value"
                      label="What job responsibilities will this employee have?"
                      outlined
                      multiple
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="6">
                  <v-select
                      v-model="contractTemplateModel.wagePeriod"
                      :items="wagePeriodTypeOptions"
                      item-text="text"
                      item-value="value"
                      label="How do you pay the employee for the work they have completed?"
                      outlined
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="6">
                  <v-text-field
                      :label="`How much to you pay the employee? ${wagePeriodHelper}`"
                      v-model="contractTemplateModel.wageRate"
                      outlined
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="contractTemplateModel.wagePeriod === 'per_day'">
                <v-col md="6">
                  <v-select
                      v-model="contractTemplateModel.wagePaymentSchedule"
                      :items="wagePaymentScheduleTypeOptions"
                      item-text="text"
                      item-value="value"
                      label="When do you pay your employee?"
                      outlined
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="6">
                  <v-select
                      label="Which day of the month do you pay your employee?"
                      v-model="contractTemplateModel.wagePaymentDate"
                      :items="wagePaymentDateOptions"
                      item-text="text"
                      item-value="value"
                      outlined
                  >
                  </v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col md="6">
                  <v-select
                      v-model="contractTemplateModel.wageTrackingType"
                      :items="wageTrackingTypeOptions"
                      item-text="text"
                      item-value="value"
                      label="How do you want to track the days that the employee has worked?"
                      outlined
                  ></v-select>
                </v-col>
              </v-row>
              <v-row v-if="contractTemplateModel.wagePeriod === 'per_day' && contractTemplateModel.wageTrackingType=== 'automatic'">
                <v-col md="6">
                  <v-select
                      v-model="contractTemplateModel.workSchedule"
                      :items="weekDayScheduleTypeOptions"
                      item-text="text"
                      item-value="value"
                      label="Which days of the week does the employee work?"
                      outlined
                      multiple
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="6">
                  <v-btn elevation="0" type="submit" color="primary" class="">
                    Save
                  </v-btn>
                  <v-btn elevation="0" outlined color="warning" class="ml-4">
                    Cancel
                  </v-btn>
                  <v-btn elevation="0" outlined color="warning" class="ml-4" @click="checkTypes">
                    Test
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
import { ContractTemplate } from "@/models/ContractTemplate";

export default {
  name: "EditEmployer",
  data() {
    return {
      tab: 0,
      tabs: ["View Timeline", "Edit Events"],
      isEdit: false,
      isLoading: false,
      contractTemplateModel: new ContractTemplate(),
      jobResponsibilities: [],
      menu: false,
      date: null,
      search: "",
      dialog3: false,
      totalRecords: 0,
      crumbs: [
        {
          text: "Contract Templates",
          disabled: false,
          href: "/admin/contracttemplate",
        },
        {
          text: "Edit Contract Template",
          disabled: false,
          href: `/admin/contracttemplate/${this.$route.params.id}/edit`,
        }
      ],

      me: "",
      headers: [
        {
          text: "Explanation",
          align: "start",
          sortable: false,
          value: "explanation",
        },

        { text: "Created Date", value: "createdAt", align: "left" },
        { text: "Actions", align: "left", sortable: false, value: "actions" },
      ],
    };
  },
  created() {
    this.isEdit = this.$route.params.id != 0;
    this.isLoading = this.isEdit ? true : false;
  },
  apollo: {
    me: gql`
      query {
        me {
          id
        }
      }
    `,

    contractTemplate: {
      query: gql`
        query GetContractTemplate($id: ID!) {
          contractTemplate(id: $id) {
            id
            name
            description
            jobTitle
            duties
            contractCurrency
            terminationNoticePeriod
            wagePaymentSchedule
            wagePaymentDate
            wagePaymentWeekDay
            wagePeriod
            wagePeriodUnitType
            wageTracking
            wageRate
            overtimeAvailable
            overtimeRate
            publicHolidayRate
            hoursOfWorkStart
            hoursOfWorkEnd
            lunchPeriod
            createdAt
            updatedAt
          }
        }
      `,
      fetchPolicy: "network-only",
      skip() {
        return !this.isEdit;
      },
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      result(response, key) {
        this.isLoading = false;
        this.contractTemplateModel.name = response.data.contractTemplate.name
        this.contractTemplateModel.description = response.data.contractTemplate.description
        this.contractTemplateModel.jobTitle = response.data.contractTemplate.jobTitle
        this.contractTemplateModel.duties = response.data.contractTemplate.duties
        this.contractTemplateModel.contractCurrency = response.data.contractTemplate.contractCurrency
        this.contractTemplateModel.terminationNoticePeriod = response.data.contractTemplate.terminationNoticePeriod
        this.contractTemplateModel.wagePaymentSchedule = response.data.contractTemplate.wagePaymentSchedule
        this.contractTemplateModel.wagePaymentDate = response.data.contractTemplate.wagePaymentDate
        this.contractTemplateModel.wagePaymentWeekDay = response.data.contractTemplate.wagePaymentWeekDay
        this.contractTemplateModel.wagePeriod = response.data.contractTemplate.wagePeriod
        this.contractTemplateModel.wagePeriodUnitType = response.data.contractTemplate.wagePeriodUnitType
        this.contractTemplateModel.wageTracking = response.data.contractTemplate.wageTracking
        this.contractTemplateModel.wageRate = response.data.contractTemplate.wageRate
        this.contractTemplateModel.overtimeAvailable = response.data.contractTemplate.overtimeAvailable
        this.contractTemplateModel.overtimeRate = response.data.contractTemplate.overtimeRate
        this.contractTemplateModel.publicHolidayRate = response.data.contractTemplate.publicHolidayRate
        this.contractTemplateModel.transportAllowanceRate = response.data.contractTemplate.transportAllowanceRate
        this.contractTemplateModel.transportAllowancePeriod = response.data.contractTemplate.transportAllowancePeriod
        this.contractTemplateModel.accommodationAllowanceRate = response.data.contractTemplate.accommodationAllowanceRate
        this.contractTemplateModel.accommodationAllowancePeriod = response.data.contractTemplate.accommodationAllowancePeriod
        this.contractTemplateModel.mealAllowanceRate = response.data.contractTemplate.mealAllowanceRate
        this.contractTemplateModel.mealAllowancePeriod = response.data.contractTemplate.mealAllowancePeriod
        this.contractTemplateModel.hoursOfWorkStart = response.data.contractTemplate.hoursOfWorkStart
        this.contractTemplateModel.hoursOfWorkEnd = response.data.contractTemplate.hoursOfWorkEnd
        this.contractTemplateModel.workSchedule = response.data.contractTemplate.workSchedule
        this.contractTemplateModel.lunchPeriod = response.data.contractTemplate.lunchPeriod
        this.contractTemplateModel.leaveAvailable = response.data.contractTemplate.leaveAvailable
        this.contractTemplateModel.annualLeaveDays = response.data.contractTemplate.annualLeaveDays
        this.contractTemplateModel.sickLeaveDays = response.data.contractTemplate.sickLeaveDays
        this.contractTemplateModel.maternityLeaveDays = response.data.contractTemplate.maternityLeaveDays
        this.contractTemplateModel.familyResponsibilityLeaveDays = response.data.contractTemplate.familyResponsibilityLeaveDays
      },
    },
    contractTemplateJobResponsibilities: {
      query: gql`
        query GetContractTemplateJobResponsibilities {
          contractTemplateJobResponsibilities {
            records
          }
        }
      `,
      fetchPolicy: "network-only",
      result(response, key) {
        return response.data.contractTemplateJobResponsibilities.records
      }
    },
    wageTrackingType: {
      query: gql`
        query {
          __type(name: "WageTrackingType") {
            wageTrackingType: enumValues {
              name
            }
          }
        }
      `,
      update: (data) => data.__type,
    },
    wagePaymentScheduleType: {
      query: gql`
        query {
          __type(name: "WagePaymentScheduleType") {
            wagePaymentScheduleType: enumValues {
              name
            }
          }
        }
      `,
      update: (data) => data.__type,
    },
    wagePeriodType: {
      query: gql`
        query {
          __type(name: "WagePeriodType") {
            wagePeriodType: enumValues {
              name
            }
          }
        }
      `,
      update: (data) => data.__type,
    },
    weekDayTypes: {
      query: gql`
        query {
          __type(name: "WeekDayType") {
            weekDayTypes: enumValues {
              name
            }
          }
        }
      `,
      update: (data) => data.__type,
    },
    weekDayScheduleType: {
      query: gql`
        query {
          __type(name: "WeekDayScheduleType") {
            weekDayScheduleType: fields {
              name
            }
          }
        }
      `,
      update: (data) => data.__type,
    },
  },
  computed: {
    contractTemplateJobResponsibilitiesOptions: function () {
      const returnObject = []
      if (this.$_.size(this.contractTemplateJobResponsibilities.records) > 0 ) {
        console.log('contractTemplateJobResponsibilities: ', this.contractTemplateJobResponsibilities.records)
      }
      return this.contractTemplateJobResponsibilities.records
    },
    wageTrackingTypeOptions: function () {
      const returnObject = []
      if (this.$_.size(this.wageTrackingType) > 0 ) {
        this.$_.forEach(this.wageTrackingType.wageTrackingType, function (value) {
          switch(value.name) {
            case 'automatic':
              returnObject.push({value: value.name, text: 'I do not track the employee\'s worked days.'})
              break;
            case 'employee_must_track':
              returnObject.push({value: value.name, text: 'The employee must track their worked days in the app.'})
              break;
            case 'employee_must_track_with_approval':
              returnObject.push({value: value.name, text: 'The employee must track their worked days and I must approve them.'})
              break;
            case 'employer_must_track':
              returnObject.push({value: value.name, text: 'I will track the days the employee has worked in the app.'})
              break;
            default:
              break;
          }
        })
      }
      return returnObject
    },
    wagePaymentScheduleTypeOptions: function () {
      const returnObject = []
      if (this.$_.size(this.wagePaymentScheduleType) > 0 ) {
        this.$_.forEach(this.wagePaymentScheduleType.wagePaymentScheduleType, function (value) {
          switch(value.name) {
            case 'end_of_month':
              returnObject.push({value: value.name, text: 'I pay at the end of the month.'})
              break;
            case 'adhoc':
              returnObject.push({value: value.name, text: 'I pay on an ad-hoc basis.'})
              break;
            default:
              break;
          }
        })
      }
      return returnObject
    },
    wagePeriodTypeOptions: function () {
      const returnObject = []
      if (this.$_.size(this.wagePeriodType) > 0 ) {
        this.$_.forEach(this.wagePeriodType.wagePeriodType, function (value) {
          switch(value.name) {
            case 'per_month':
              returnObject.push({value: value.name, text: 'I pay a set amount each month.'})
              break;
            case 'per_day':
              returnObject.push({value: value.name, text: 'I pay on a per day basis.'})
              break;
            default:
              break;
          }
        })
      }
      return returnObject
    },
    wagePeriodHelper: function () {
      let returnText = ''
      if (this.contractTemplateModel.wagePeriod) {
        switch(this.contractTemplateModel.wagePeriod) {
          case 'per_month':
            returnText = '(Each month)'
            break;
          case 'per_day':
            returnText = '(Each day)'
            break;
          default:
            returnText = ''
        }
      }
      return returnText
    },
    weekDayScheduleTypeOptions: function () {
      const returnObject = []
      if (this.$_.size(this.weekDayScheduleType) > 0 ) {
        this.$_.forEach(this.weekDayScheduleType.weekDayScheduleType, (value) => {
          returnObject.push({ value: value.name, text: this.$_.capitalize(value.name) })
        })
      }
      return returnObject
    },
    wagePaymentDateOptions: function () {
      const textOptions = ['1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th', '11th', '12th', '13th', '14th', '15th', '16th', '17th', '18th', '19th', '20th', '21st', '22nd', '23rd', '24th', '25th', '26th', '27th', '28th']
      const returnObject = []
      this.$_.forEach(textOptions, (value, key) => {
        returnObject.push({ value: key + 1, text: value })
      })
      returnObject.push({ value: 'LAST_DAY_OF_MONTH', text: 'Last day of the month' })
      return returnObject
    }
  },
  methods: {
    checkTypes () {
      console.log('########Check Types#########')
      console.dir(this.wageTrackingType)
      console.dir(this.weekDayTypes)
    },
    async save() {
      this.isLoading = true;
      const self = this;
      if (!self.$refs.userForm.validate()) {
        self.$swal("Error", "Validation failed", "error");

        self.isLoading = false;
        return;
      }

      if (self.isEdit) {
        await this.updateUser()
          .then(() => {
            this.$swal("Success!", "Consultant was updated", "success");
            window.location.href = `/admin/consultant/${self.$route.params.id}/edit`;
          })
          .catch((e) => {
            this.$swal(
              "Error!",
              "something went wrong when trying to update the Consultant, check data and try again later!",
              "error"
            );
          });
      } else {
        await this.createUser()
          .then(() => {
            this.$swal("Success!", "Consultant was created", "success");
            window.location.href = `/admin/consultant/${self.contractTemplateModel.id}/edit`;
          })
          .catch((e) => {
            this.$swal(
              "Error!",
              `Something went wrong when creating the Consultant, check data and try again later!`,
              "error"
            );
          });
      }
      this.isLoading = false;
    },
    async updateUser() {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation updateUser($user: UserUpdateInput!) {
              updateUser(user: $user) {
                id
                updated
              }
            }
          `,
          variables: {
            user: {
              id: self.userModel.id,
              firstName: self.userModel.firstName,
              lastName: self.userModel.lastName,
              title: self.userModel.title,
              relationship: self.userModel.relationship,
              gender: self.userModel.gender,
              race: self.userModel.race,
              telNumber: self.userModel.telNumber,
              altNumber: self.userModel.altNumber,
              email: self.userModel.email,
              businessEmail: self.userModel.businessEmail,
              faxNumber: self.userModel.faxNumber,
              region: self.userModel.region,
              postalCode: self.userModel.postalCode,
              bio: self.userModel.bio,
              rating: self.userModel.rating,
              // dob: self.userModel.dob,
              idNumber: self.userModel.idNumber,
              passport: self.userModel.passport,
              fullAddress: self.userModel.fullAddress,
              industry: self.userModel.industry,
              role: self.userModel.role,
              referral: self.userModel.referral,
              branch: self.userModel.branch,
              branchName: self.userModel.branchName,
              consultant: self.userModel.consultant,
              mailingPref: self.userModel.mailingPref,
              companyName: self.userModel.companyName,
            },
          },
        });
        if (!response) {
          throw "Something went wrong when creating the Consultant!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
      } catch (e) {
        throw e.message;
      }
    },
    async createUser() {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation createUser($user: UserCreateInput!) {
              createUser(user: $user) {
                id
              }
            }
          `,
          variables: {
            user: {
              firstName: self.userModel.firstName,
              lastName: self.userModel.lastName,
              title: self.userModel.title,
              relationship: self.userModel.relationship,
              gender: self.userModel.gender,
              race: self.userModel.race,
              telNumber: self.userModel.telNumber,
              altNumber: self.userModel.altNumber,
              email: self.userModel.email,
              businessEmail: self.userModel.businessEmail,
              faxNumber: self.userModel.faxNumber,
              bio: self.userModel.bio,
              rating: self.userModel.rating,
              // dob: self.userModel.dob,
              idNumber: self.userModel.idNumber,
              passport: self.userModel.passport,
              fullAddress: self.userModel.fullAddress,
              industry: self.userModel.industry,
              role: "employee",
              referral: self.userModel.referral,
              branch: self.userModel.branch,
              branchName: self.userModel.branchName,
              consultant: self.userModel.consultant,
              postalCode: self.userModel.postalCode,
              region: self.userModel.region,
              mailingPref: self.userModel.mailingPref,
              companyName: self.userModel.companyName,
            },
          },
        });

        if (!response) {
          throw "Something went wrong when creating the Employer!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
        if (response && response.data.createUser) {
          self.userModel.id = response?.data?.createUser?.id;
        }
      } catch (e) {
        throw e.message;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
